/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card>
    <validation-observer ref="userCreateFormValidation">
      <b-form
        @submit.prevent="submitForm"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="h-name"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="h-name"
                  v-model="user.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Surname"
              label-for="h-surname"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Surname"
                rules="required"
              >
                <b-form-input
                  id="h-surname"
                  v-model="user.surname"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Surname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="h-email"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="h-email"
                  v-model="user.email"
                  :state="errors.length > 0 ? false:null"
                  type="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Password"
              label-for="h-password"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|password"
                vid="Password"
              >
                <b-form-input
                  id="h-password"
                  v-model="user.password"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Repeat Password"
              label-for="h-repeatPassword"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="repeatPassword"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="h-repeatPassword"
                  v-model="user.repeatPassword"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Repeat Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Role"
              label-for="h-role"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="role"
                rules="required"
              >
                <b-form-select
                  id="h-form"
                  v-model="user.role"
                  :state="errors.length > 0 ? false:null"
                  :options="roles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BCard,
} from 'bootstrap-vue'

import {
  required, email, confirmed, password,
} from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'Createuser',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BCard,
  },
  data() {
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        role: '',
        password: '',
        repeatPassword: '',
        status: 'active'
      },
      roles: [
        {
          value: 'admin',
          text: 'Admin',
        },
        {
          value: 'partner',
          text: 'Partner',
        },
      ]
    }
  },
  methods: {
    submitForm() {
      const self = this

      this.$refs.userCreateFormValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('app/createUser', this.user).catch().then(() => {
          self.$router.push('/users')
        })
        }
      })
    }
  },
}
</script>
